import * as React from 'react'
import { List, Datagrid, TextField, Filter, TextInput, ReferenceField, ReferenceInput, SelectInput, NumberField, usePermissions, ListProps } from 'react-admin';

const DistibutorsFilter:React.FC<any> = (props:any)=>(
    <Filter {...props}>
        <TextInput label="Search" source="q" alwaysOn resettable/>
    </Filter>
)

const DistibutorsList:React.FC<ListProps> = (props:ListProps)=>{
    const {permissions} = usePermissions();
    return (
    <List {...props} exporter={false} bulkActionButtons={false} filters={<DistibutorsFilter/>} hasCreate={permissions && permissions.includes("Admin")}>
        <Datagrid rowClick="edit">
            <TextField source="name" label="Название" sortable={true}/>
            <NumberField source="companiesCount" label="Компаний" sortable={false}/>
            <NumberField source="usersCount" label="Пользователей" sortable={false}/>
        </Datagrid>
    </List>
    );
}

export default DistibutorsList;
