import React from 'react';
import { Create, CreateProps } from 'react-admin';
import {DistibutorForm} from './Form';

const DistibutorCreate:React.FC<CreateProps> = props => (
    <Create {...props}>
        <DistibutorForm/>
    </Create>
);

export default DistibutorCreate;