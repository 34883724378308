import * as React from 'react'
import { List, Datagrid, TextField, Filter, TextInput, ListProps, DateField } from 'react-admin';

const DevicesFilter:React.FC<any> = (props:any)=>(
    <Filter {...props}>
        <TextInput label="Search" source="q" alwaysOn resettable/>
    </Filter>
)

const DevicesList:React.FC<ListProps> = (props:ListProps)=>(
    <List {...props} exporter={false} bulkActionButtons={false} filters={<DevicesFilter/>}>
        <Datagrid rowClick={undefined}>
            <TextField source="id" label="Id" sortable={true}/>
            <TextField source="unique" label="Unique" sortable={true}/>
            <TextField source="comment" label="Comment" sortable={true}/>
            <TextField source="company" label="Company" sortable={true}/>
            <DateField source="registration" label="Registration" sortable/>
        </Datagrid>
    </List>
)

export default DevicesList;
