import React from "react";
import * as Ra from 'react-admin';
import { SizeField } from "../components/SizeField";
import { TariffTypes } from "../tariffs/TariffTypes";

export const Statistics = (props:any)=>{
    return (
        <Ra.Show resource="statistics" id="0" title="Статистика" >
            <Ra.SimpleShowLayout>
                <SizeField source="totalContentSize" label="Всего контента"/>
                {/*<SizeField source="totalReservations" label="Всего зарезервированно под контент"/>*/}
                <Ra.NumberField source="totalDevices" label="Всего плееров"/>
                <Ra.ArrayField source="companiesByTariffType" label="Компаний по типу текущего тарифа">
                    <Ra.Datagrid bulkActionButtons={false}>
                        <Ra.SelectField source="type" choices={TariffTypes} label="Тип"/>
                        <Ra.NumberField source="count" label="Количество"/>
                    </Ra.Datagrid>
                </Ra.ArrayField>
                <Ra.WrapperField label="Текущий месяц">
                    <Ra.DateField source="monthStart"/>
                    -
                    <Ra.DateField source="monthEnd"/>
                </Ra.WrapperField>
                <SizeField source="monthlyContentSize" label="Нового контента в текущем месяце"/>
                <Ra.NumberField source="monthlyDevices" label="Новых плееров в текущем месяце"/>
                <Ra.NumberField source="personalWantConfirm" label="Периодов ожидающих подтверждения"/>
                <Ra.NumberField source="personalStart" label="Новых периодов в текущем месяце"/>
                <Ra.NumberField source="personalEnd" label="Заказчивающихся периодов в текущем месяце"/>
                <Ra.NumberField source="personalPlannedPaymentsSum" label="Сумма ожидаемых оплат в текущем месяце"/>
                <Ra.NumberField source="personalPaymentsSum" label="Сумма полученных оплат в текущем месяце"/>
                <Ra.NumberField source="personalClosedSum" label="Сумма закрытых оплат в текущем месяце"/>
                <Ra.NumberField source="personalExpiredMonthlySum" label="Сумма задолжности в текущем месяце"/>
                <Ra.NumberField source="personalExpiredSum" label="Сумма задолжности за все время"/>
            </Ra.SimpleShowLayout>
        </Ra.Show>
    );
}