import React from "react";
import * as Ra from 'react-admin';
import { SizeField } from "../components/SizeField";
import { EditPersonalPeriodButton } from "./EditPersonalPeriodButton";
import { PeriodShowToolbar } from "./PeriodShowToolbar";
import { PersonalPeriodPaymentTypes } from "./PersonalPeriodPaymentTypes";
import { AddPersonalPeriodButton } from "./AddPersonalPeriodButton";
import { ConfirmPeriodButton } from "./ConfirmPeriodButton";

export const PersonalPeriodShow = (props:any)=>(
    <Ra.Show {...props} title=' '>
        <Ra.SimpleShowLayout>
            <Ra.NumberField source="tariff.pricePerPlayer" label="Цена за плеер"/>
            <Ra.NumberField source="tariff.pricePerUser" label="Цена за пользователя"/>
            <Ra.NumberField source="tariff.pricePerStorageUnit" label="Цена за единицу хранилища"/>
            <SizeField source="tariff.storageUnit" label="Размер единицы хранилища"/>
            <Ra.NumberField source="details.sum" label="Сумма"/>
            <Ra.SelectField source="details.paymentType" choices={PersonalPeriodPaymentTypes} label="Тип оплаты"/>
            <Ra.TextField source="details.comment" label="Комментарий" fullWidth/>
            <Ra.ArrayField source="details.payments" label="Платежи">
                <Ra.Datagrid bulkActionButtons={false}>
                    <Ra.NumberField source="sum" label="Сумма"/>
                    <Ra.DateField source="plannedPaymentDate" label="Планируемая дата оплаты"/>
                    <Ra.DateField source="paymentDate" label="Дата оплаты"/>
                    <Ra.DateField source="closeDate" label="Дата закрытия"/>
                    <Ra.TextField source="comment" label="Комментарий" fullWidth/>
                </Ra.Datagrid>
            </Ra.ArrayField>
            <Ra.ArrayField source="details.documents" label="Документы">
                    <Ra.Datagrid bulkActionButtons={false}>
                        <Ra.FileField source="file.src" title="file.title" label="Документ" target="_blank"/>
                        <Ra.TextField source="comment" label="Комментарий" fullWidth={true}/>
                    </Ra.Datagrid>
            </Ra.ArrayField>
        </Ra.SimpleShowLayout>
        <PeriodShowToolbar {...props}>
            <ConfirmPeriodButton style={{marginRight: 20}} period={props.record}/>
            <EditPersonalPeriodButton/>
            <AddPersonalPeriodButton label="Дублировать период" periodId={props.record?.id} style={{marginLeft:'1rem'}}/>
        </PeriodShowToolbar>
            {/*<SelectInput source="details.paymentType" choices={PersonalPaymentTypes} label="Тип оплаты"/>
            <FormDataConsumer>
                    {({formData})=> 
                        formData.details.paymentType==2 && (<NumberInput source="details.creditPercent" label="Процент кредита" min={0} />)
                    }
            </FormDataConsumer>
            <DateInput source="details.plannedPaymentDate" label="Планируемая дата оплаты"/>
            <DateInput source="details.paymentDate" label="Дата оплаты"/>
            <DateInput source="details.closeDate" label="Дата закрытия"/>
            <TextInput source="details.comment" label="Комментарий" fullWidth multiline/>
            <ArrayInput source="details.documents" label="Документы">
                    <SimpleFormIterator>
                        <FileInput source="file" label="Документ">
                            <FileField source="src" title="title" target="_blank"/>
                        </FileInput>
                        <TextInput source="comment" label="Комментарий" fullWidth={true}/>
                    </SimpleFormIterator>
                </ArrayInput>*/}
    </Ra.Show>
)