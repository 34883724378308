import React from 'react';
import { SimpleForm, TextInput, Create, required,CreateProps, SelectInput } from 'react-admin';
import { Countries } from './Countries';
import { Currencies } from './Currencies';

const GroupsCreate:React.FC<CreateProps> = props => (
    <Create {...props}>
        <SimpleForm>
            <TextInput source="name" label="Название"  validate={required()}/>
            <TextInput source="email" label="Email пользователя"  validate={required()}/>
            <TextInput source="info.country" label="Страна" validate={required()} />
            <TextInput source="info.currency" label="Валюта" />
            <TextInput source="info.name" label="Название Юр.лица" />
            <TextInput source="info.identifier" label="Идентификатор Юр.лица(ИНН)" />
            <TextInput source="info.personName" label="ФИО контактного лица" validate={required()} />
            <TextInput source="info.personEmail" label="Email контактного лица" validate={required()} />
            <TextInput source="info.personPhone" label="Телефон контактного лица" />
            <TextInput source="info.comment" label="Комментарий" fullWidth multiline />
        </SimpleForm>
    </Create>
);

export default GroupsCreate;