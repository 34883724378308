import * as React from 'react'
import { List, Datagrid, ListProps, TextField, Filter, TextInput, SelectField, BooleanField, NumberField, ReferenceField, FunctionField, DateField, BooleanInput, TopToolbar, CreateButton } from 'react-admin';
import { TariffTypes } from './TariffTypes';
import { SizeField } from '../components/SizeField';

const TariffFilter:React.FC<any> = (props:any)=>(
    <Filter {...props}>
        <TextInput label="Поиск" source="q" alwaysOn resettable/>
        <BooleanInput label="Публичные" source="isPublic" alwaysOn={true}/>
    </Filter>
)

const Toolbar = (props:any)=>(
    <TopToolbar>
        <CreateButton label="СОЗДАТЬ БАЗОВЫЙ ТАРИФ"/>
    </TopToolbar>
)

const TariffList:React.FC<ListProps> = (props:ListProps)=>(
    <List {...props} exporter={false} bulkActionButtons={false} filters={<TariffFilter/>} filterDefaultValues={{isPublic:true}} actions={<Toolbar/>}>
        <Datagrid rowClick="edit">
            <SelectField source="type" label="Тип" choices={TariffTypes}/>
            <DateField source="disabled" label="Отключен"/>
            <BooleanField source="isPublic" label="Публичный"/>
            <NumberField source="numberOfDevices" label="Количество устройств"/>
            <SizeField source="storageSize" label="Размер хранилища"/>
            <NumberField source="numberOfUsers" label="Количество пользователей"/>
            <TextField source="productId" label="Идентификатор продукта"/>
            <ReferenceField source="externalSellerId" reference="externalsellers" label="Идентификатор приложения">
                <TextField source="identifier" />
            </ReferenceField>
        </Datagrid>
    </List>
)

export default TariffList;
