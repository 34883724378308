import React, { useCallback, useState } from "react";
import { Dialog, DialogTitle, DialogContent, DialogActions,Stack } from '@mui/material';
import { Edit, Cancel } from '@mui/icons-material';
import * as Ra from 'react-admin';
import { PersonalPeriodPaymentTypes } from "./PersonalPeriodPaymentTypes";
import useFileTransform from "../utils/useFileTransform";

export const EditPersonalPeriodButton = (props:any)=>{
    const notify = Ra.useNotify();
    const refresh = Ra.useRefresh();
    const record = Ra.useRecordContext();
    const { permissions } = Ra.usePermissions();
    const [showDialog, setShowDiadlog] = useState(false);

    const onEdit = useCallback(async ()=>{
        setShowDiadlog(true);
    },[notify,record,setShowDiadlog]);

    const onClose=useCallback((reason)=>{
        console.log(reason);
        if(reason?.type==='mouseup') return;
        setShowDiadlog(false);
    },[setShowDiadlog]);

    const onSuccess = useCallback(()=>{
        refresh();
        onClose('complete');
    },[refresh,onClose]);

    const onError = useCallback((e)=>{
        var message = "Unknown error";
        if(e instanceof Error) message=e.message;
        if(e instanceof Ra.HttpError) message = e.body?.message || JSON.stringify(e.body);
        notify(message, { type: "error"});
    },[notify]);

    return (
        <>
            <Ra.Button onClick={onEdit} label="Редактировать" variant="contained" size="medium" style={props.style}><Edit/></Ra.Button>
            <Dialog
                open={showDialog}
                onClose={onClose}
                maxWidth='md'
                aria-label="Изменить персональный период">
                <DialogTitle>Изменить персональный период</DialogTitle>
                    <Ra.EditBase resource="tariffperiods" id={record.id} mutationMode='pessimistic' redirect={false} mutationOptions={{onSuccess, onError}} transform={useFileTransform()}>
                        <Ra.Form >
                            <DialogContent>
                                <Stack>
                                    <Ra.SelectInput source="details.paymentType" choices={PersonalPeriodPaymentTypes} label="Тип оплаты"/>
                                    <Ra.FormDataConsumer>
                                            {({formData})=> 
                                                formData.details.paymentType==2 && (<Ra.NumberInput source="details.creditPercent" label="Процент кредита" min={0} />)
                                            }
                                    </Ra.FormDataConsumer>
                                    <Ra.TextInput source="details.comment" label="Комментарий к периоду" fullWidth multiline/>
                                    <Ra.ArrayInput source="details.payments" label="Платежи">
                                        <Ra.SimpleFormIterator inline disableReordering disableClear disableRemove={!(permissions && permissions.includes("Admin"))}>
                                            <Ra.NumberInput source="sum" label="Сумма" validate={Ra.required()} min={1} helperText={false}/>
                                            <Ra.DateInput source="plannedPaymentDate" label="Планируемая дата оплаты" validate={Ra.required()} defaultValue={null} helperText={false}/>
                                            <Ra.DateInput source="paymentDate" label="Дата оплаты" helperText={false}/>
                                            <Ra.DateInput source="closeDate" label="Дата закрытия" disabled={!(permissions && permissions.includes("Admin"))} helperText={false}/>
                                            <Ra.TextInput source="comment" label="Комментарий к платежу" fullWidth multiline/>
                                        </Ra.SimpleFormIterator>
                                    </Ra.ArrayInput>
                                    <Ra.ArrayInput source="details.documents" label="Документы">
                                        <Ra.SimpleFormIterator>
                                            <Ra.FileInput source="file" label="Документ">
                                                <Ra.FileField source="src" title="title" target="_blank"/>
                                            </Ra.FileInput>
                                            <Ra.TextInput source="comment" label="Комментарий" fullWidth={true}/>
                                        </Ra.SimpleFormIterator>
                                    </Ra.ArrayInput>
                                </Stack>
                            </DialogContent>
                            <DialogActions>
                                <Ra.Button label="ОТМЕНА" variant='contained' size="medium" onClick={onClose}><Cancel/></Ra.Button>
                                <Ra.SaveButton  alwaysEnable/>
                            </DialogActions>
                        </Ra.Form>
                    </Ra.EditBase>
            </Dialog>
        </>
    );
}