//import * as russianMessages from '../../node_modules/ra-language-russian/index';
const russianMessages:any = require('ra-language-russian')

export default {
    ...russianMessages,
    ra:{
        ...russianMessages.ra,
        auth:{
            ...russianMessages.ra.auth,
            username: "Почта"
        }
    },
    resources:{
        companies:{
            name: 'Компании'
        },
        signuprequests:{
            name: 'Запросы регистрации'
        },
        users:{
            name: 'Пользователи'
        },
        externalsellers:{
            name: 'Внешние продажи'
        },
        tariffs:{
            name: 'Тарифы'
        },
        distibutors:{
            name: 'Дистрибьюторы'
        },
        devices:{
            name: 'Устройства'
        }
    },
};