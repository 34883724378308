import React  from "react";
import * as Ra from 'react-admin';
import { useWatch } from 'react-hook-form';
import { SizeInput } from "../components/SizeInput";

const requiredFromZeroGridPrice = (message = 'Требуется указать цену от 1')=>
    (value:any)=>{ console.log("val",value); return value?.find((v:any)=>v.limit==1) ? null : message;}

const PriceGrid = ({LimitComponent,...props}:any)=>{
    const periods = useWatch({name: "periods"});
    console.log('PriceGrid',periods);
    return (
        <Ra.ArrayInput source={props.source} label={props.label} validate={[Ra.required(), requiredFromZeroGridPrice(props.startPriceError)]}>
            <Ra.SimpleFormIterator disableReordering disableClear inline>
                <LimitComponent source="limit" min={1} label={props.limitLabel} validate={Ra.required()}/>
                {periods?.map((p:any)=>(<Ra.NumberInput source={"price_"+p.months} label={`Цена за ${p.months}м`} validate={Ra.required()}/>))}
            </Ra.SimpleFormIterator>
        </Ra.ArrayInput>
    )
}

export const TariffContructor = (props:any)=>{
    return (
        <Ra.Edit resource="tariffConstructor" id="0" title="Публичный тариф" redirect={false} mutationMode='pessimistic'>
            <Ra.TabbedForm syncWithLocation={false}>
                <Ra.TabbedForm.Tab label="Опции">
                    <Ra.NumberInput source="gracePeriodDays" label="Дней на оплату(Grace period)" min={0} validate={Ra.required()}/>
                    <Ra.ArrayInput source="periods" label="Периоды" validate={Ra.required()}>
                        <Ra.SimpleFormIterator disableReordering disableClear inline>
                            <Ra.NumberInput source="months" label="Месяцев" validate={Ra.required()}/>
                            {/*<Ra.TextInput source="name" label="Название" validate={Ra.required()}/>*/}
                        </Ra.SimpleFormIterator>
                    </Ra.ArrayInput>
                </Ra.TabbedForm.Tab>
                <Ra.TabbedForm.Tab label="Сетка цен на устройства">
                    <Ra.NumberInput source="devicesMax" label="Максимум устройств" min={1} validate={Ra.required()}/>
                    <Ra.NumberInput source="devicesStep" label="Шаг изменения кол-ва устройств" min={1} validate={Ra.required()}/>
                    <PriceGrid source="devicePrices" LimitComponent={Ra.NumberInput} label="Сетка цен на 1шт" limitLabel="Ступень(Количество>=)" startPriceError="Требуется указать цену от 1 плеера"/>
                </Ra.TabbedForm.Tab>
                <Ra.TabbedForm.Tab label="Сетка цен на хранилище">
                    <SizeInput source="storageMax" label="Максимум хранилища" validate={Ra.required()}/>
                    <SizeInput source="storageStep" label="Шаг изменения размеров хранилища" validate={Ra.required()}/>
                    <PriceGrid source="storagePrices" LimitComponent={SizeInput} label="Сетка цен на 1Гб" limitLabel="Ступень(Размер>=)" startPriceError="Требуется указать цену от 1b"/>
                </Ra.TabbedForm.Tab>
                <Ra.TabbedForm.Tab label="Сетка цен на пользователей">
                    <Ra.NumberInput source="usersMax" label="Максимум пользователей" validate={Ra.required()}/>
                    <Ra.NumberInput source="userStep" label="Шаг изменения кол0ва пльзователейл" validate={Ra.required()}/>
                    <PriceGrid source="usersPrices" LimitComponent={Ra.NumberInput} label="Сетка цен на 1 пользователя" limitLabel="Ступень(Количество>=)" startPriceError="Требуется указать цену от 1 пользователя"/>
                </Ra.TabbedForm.Tab>
            </Ra.TabbedForm>
        </Ra.Edit>
    );
}