import React from "react";
import * as Ra from 'react-admin';
import { PeriodShowToolbar } from "./PeriodShowToolbar";
import { ConfirmPeriodButton } from "./ConfirmPeriodButton";

export const BasePeriodShow = (props:any)=>{
    return (
        <Ra.Show {...props} title=' '>
            <Ra.SimpleShowLayout>
                <Ra.ReferenceField source="tariff.id" reference="tariffs" label="Тариф">
                    <Ra.TextField source="id"/>
                </Ra.ReferenceField>
            </Ra.SimpleShowLayout>
            <PeriodShowToolbar>
                <ConfirmPeriodButton style={{marginRight: 20}} period={props.record}/>
            </PeriodShowToolbar>
        </Ra.Show>
    )
}