import React, { useCallback, useState } from "react";
import { CircularProgress } from '@mui/material';
import { DoneAll} from '@mui/icons-material';
import * as Ra from 'react-admin';
import { baseUrl, httpClient } from "../dataProvider";

export const ConfirmPeriodButton = (props:any)=>{
    const { permissions } = Ra.usePermissions();
    const notify = Ra.useNotify();
    const { refetch } = Ra.useListContext();

    const [loading, setLoading] = useState(false);

    const onConfirm = useCallback(async ()=>{
        setLoading(true);
        try{
            const periodId = props.period.id;
            const res = await httpClient(`${baseUrl}/tariffperiods/${periodId}/confirm`, { method: 'POST' });
            if(res.status == 200){
                refetch();
            } else throw Error("Can`t confirm period");
        }catch(e){
            var message = "Unknown error";
            if(e instanceof Error) message=e.message;
            if(e instanceof Ra.HttpError) message = e.body;
            notify(message, { type: "error"});
        }
        setLoading(false);
    },[setLoading, refetch, props.period]);

    if(!permissions || !permissions.includes("Admin"))
        return null;
    if(!props.period || props.period.confirmTime)
        return null;

    return (
        <Ra.Button label="Подтвердить" variant="contained" size="medium" color="success" disabled={loading} style={props.style} onClick={onConfirm}>
            {loading ? <CircularProgress size='1rem'/> : <DoneAll/> }
        </Ra.Button>
    )
}
