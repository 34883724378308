import React from "react";
import { ReferenceInput, SelectInput, SimpleForm, TextInput, usePermissions, FormDataConsumer, BooleanInput, PasswordInput, useGetIdentity, useRecordContext, required } from 'react-admin';

import {userTypes} from './types';

export const UserForm=(props:any)=>{
    const {permissions} = usePermissions();
    const record = useRecordContext();
    const { identity } = useGetIdentity();
    return (
        <SimpleForm {...props}>
            <TextInput source="name" label="Имя" validate={required()}/>
            <TextInput source="email" label="Email" disabled={!!record?.id} validate={required()}/>
            <ReferenceInput source="companyId" label="Компания" reference="companies">
                <SelectInput optionText='name' validate={required()}/>
            </ReferenceInput>
            {permissions && permissions.includes("Admin") && (
                <SelectInput source="type" label="Тип" choices={userTypes} validate={required()}/>
            )}
            <FormDataConsumer>
            {
                ({formData})=>
                    identity && identity.id && formData.id===identity.id &&
                    (<BooleanInput source="resetPassword" label="Сбросить пароль"/>)
            }
            </FormDataConsumer>
            <FormDataConsumer>
            {
                ({formData})=>
                    formData.resetPassword &&
                    (<PasswordInput source="password" label="Новый пароль" validate={required()}/>)
            }
            </FormDataConsumer>
        </SimpleForm>
    );
}