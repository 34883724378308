export const SizeNames = ["b", "kb", "mb", "gb", "tb", "pb"];

const numFormat = new Intl.NumberFormat('ru-RU', { maximumFractionDigits:2 });

export function beutifySize(size:number)
{
    var i = 0;
    var s = size;
    while ((s / 1024.0) >= 1)
    {
        s /= 1024.0;
        i++;
        if (i > SizeNames.length - 1) break;
    }
    return `${numFormat.format(s)}${SizeNames[i]}`;
}

export function getMultiplier(size:number)
{
    if(!size) return 0;
    for(var i =0; i < SizeNames.length; i++){
        if(size % Math.pow(1024, i)!=0){
            return i==0? 0 : i-1;
        }
    }
    return SizeNames.length-1;
}