import React, { useCallback, useEffect, useRef, useState } from "react";
import * as Ra from 'react-admin';
import clsx from 'clsx';
import { TextField, Select, InputAdornment, MenuItem } from '@mui/material';
import { FieldTitle, InputHelperText } from "react-admin";
import { getMultiplier, SizeNames } from "../utils/beutifySize";
import { ifDefined } from "../utils/ifDefined";

export const SizeInput = (props:any)=>{
  const {
    field,
    fieldState: { error, invalid, isTouched },
    formState: { isSubmitted },
    isRequired,
  } = Ra.useInput(props);

  const [value, setValue] = useState(1);
  const [multiplier, setMultiplier] = useState(0);

  useEffect(()=>{
    const v = field?.value;
    if(typeof v == 'undefined') return;
    //if(!v) return;
    const ind = getMultiplier(v);
    const val = v / Math.pow(1024, ind);
    setValue(val);
    if(val!=0)
      setMultiplier(ind);
    //console.log('SizeInput val',val)
  },[field?.value]);

  const onMultiplierChange = useCallback((v)=>{
    const value = v?.target?.value;
    if(!value) return;
    const ind = SizeNames.indexOf(value);
    if(-1 == ind) return;
    setMultiplier(ind);
  },[setMultiplier]);

  const onValueChange = useCallback((v)=>{
    const val = v?.target?.value;
    //if(!val) return;
    if(typeof val == 'undefined') return;
    setValue(val);
  },[setValue]);

  useEffect(()=>{
    const v = (value || 0)*(Math.pow(1024, multiplier));
    if(v != field?.value){
      field?.onChange(v)
    }
  },[field?.onChange,value,multiplier])

  return (
        <TextField 
          variant="filled"
          size="small"
          type="number"
          fullWidth={props.fullWidth}
          value={value}
          onChange={onValueChange}
          className={clsx('ra-input', `ra-input-${props.source}`, props.className)}
          disabled={props.disabled}
          helperText={
            <InputHelperText touched={isTouched || isSubmitted} error={error?.message} helperText={props.helperText}/>
          } 
          label={
            <FieldTitle label={props.label}
              source={props.source}
              resource={props.resource}
              isRequired={isRequired}/> 
          }
          inputProps={{min: ifDefined(props.min, 1)}}
          InputProps={{ endAdornment: (
            <InputAdornment position="end">
              <Select value={SizeNames[multiplier]} variant="standard" onChange={onMultiplierChange} disabled={props.disabled || props.disabledMuliplier}>
                { SizeNames.map((v:string)=>(<MenuItem value={v}>{v}</MenuItem>)) }
              </Select>
            </InputAdornment>)
          }} />
    )
}