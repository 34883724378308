import React, { useCallback, useEffect, useState } from "react";
import { Dialog, DialogTitle, DialogContent, DialogActions,Grid, CircularProgress } from '@mui/material';
import { Add, Cancel } from '@mui/icons-material';
import * as Ra from 'react-admin';
import { PersonalPeriodPaymentTypes } from "./PersonalPeriodPaymentTypes";
import { useFieldArray } from 'react-hook-form';

import { baseUrl, httpClient } from "../dataProvider";
import { SizeInput } from "../components/SizeInput";
import { UTCDateTimeInput } from "../components/UTCDateTimeInput";

const SyncSum = (props:any)=>{
    const { field: { value : totalSum } } = Ra.useInput({...props, source: 'details.sum' })
    //const { field: {value: payments, onChange}} = Ra.useInput({...props, source : 'details.payments[0].sum'})
    //const { fields: payments, update } = useFieldArray({name: 'details.payments'})
    const { fields, update } = Ra.useArrayInput();
    const {index} = Ra.useSimpleFormIteratorItem();
    

    //console.log('SyncSum payments', payments)
    useEffect(()=>{
        
        console.log('Total sum changed',totalSum, index, fields);
        if(0!=index) return;
        if(fields && fields.length===1){
            const rec = fields[0] as any;
            if(rec.sum !== totalSum){
                console.log('Total sum update');
                update(0, {...fields[0], sum: totalSum})
            }
        }
        //update(0, {...payments[0], sum: totalSum})
    },[totalSum])
    return <></>
}

export const AddPersonalPeriodButton = (props:any)=>{
    const notify = Ra.useNotify();
    const { refetch } = Ra.useListContext();
    const record = Ra.useRecordContext();
    const [showDialog, setShowDiadlog] = useState(false);
    const [initial, setInitial] = useState<any>(undefined);

    const onAdd = useCallback(async ()=>{
        setShowDiadlog(true);
        try{
            const periodId = record.companyId? `/${record.id}` : '';
            const res = await httpClient(`${baseUrl}/tariffperiods/${record.companyId || record.id}/personal_prototip${periodId}`);
            if(res.json){
                setInitial(res.json);
            } else throw Error("Can`t create new period");
        }catch(e){
            var message = "Unknown error";
            if(e instanceof Error) message=e.message;
            if(e instanceof Ra.HttpError) message = e.body;
            notify(message, { type: "error"});
            setShowDiadlog(false);
        }
    },[notify,setInitial,record,setShowDiadlog]);

    const onClose=useCallback((reason)=>{
        console.log(reason);
        if(reason?.type==='mouseup') return;
        setShowDiadlog(false);
        setInitial(undefined);
    },[setShowDiadlog,setInitial]);

    const onSuccess = useCallback(()=>{
        refetch();
        onClose('complete');
    },[refetch,onClose]);

    const onError = useCallback((e)=>{
        var message = "Unknown error";
        if(e instanceof Error) message=e.message;
        if(e instanceof Ra.HttpError) message = e.body?.message || JSON.stringify(e.body);
        notify(message, { type: "error"});
    },[notify]);

    return (
        <>
            <Ra.Button onClick={onAdd} label="Добавить персональный период" variant="contained" size="medium" style={props.style} {...props}><Add/></Ra.Button>
            <Dialog
                open={showDialog}
                onClose={onClose}
                aria-label="Добавить персональный период"
                maxWidth="md">
                <DialogTitle>Добавить персональный период</DialogTitle>
                { initial && 
                        <Ra.CreateBase resource="tariffperiods" redirect={false} record={initial} mutationOptions={{onSuccess, onError}}>
                            <Ra.Form >
                                <DialogContent>
                                    <Grid container spacing={1}>
                                    <Grid item xs={6}>
                                        {/*@ts-ignore*/}
                                        <UTCDateTimeInput source="startTime" label="Начало" fullWidth validate={Ra.required()}/>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Ra.NumberInput source="duration" label="Период подписки(в месяцах)" fullWidth min={1} validate={Ra.required()}/>
                                    </Grid>
                                    {/*<Grid item xs={4}>
                                        <DateTimeInput source="expireTime" label="Конец" fullWidth disabled/>
                                    </Grid>*/}
                                    <Grid item xs={6}>
                                        <Ra.NumberInput source="tariff.numberOfDevices" label="Количество устройств" min={0} fullWidth validate={Ra.required()}/>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Ra.NumberInput source="tariff.pricePerPlayer" label="Цена за устройство в период" min={0} fullWidth validate={Ra.required()}/>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Ra.NumberInput source="tariff.numberOfUsers" label="Количество пользователей" min={0} fullWidth validate={Ra.required()}/>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Ra.NumberInput source="tariff.pricePerUser" label="Цена за пользователя в период" min={0} fullWidth validate={Ra.required()}/>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <SizeInput source="tariff.storageSize" label="Размер хранилища" min={0} fullWidth validate={Ra.required()}/>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Ra.NumberInput source="tariff.pricePerStorageUnit" label="Цена за Гб в период" min={0} fullWidth validate={Ra.required()}/>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Ra.NumberInput source="details.sum" label="Сумма" min={1} fullWidth validate={Ra.required()}/>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Ra.SelectInput source="details.paymentType" choices={PersonalPeriodPaymentTypes} label="Тип оплаты" fullWidth validate={Ra.required()}/>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Ra.ArrayInput source="details.payments" label="Платежи" validate={Ra.required()}>
                                            <Ra.SimpleFormIterator inline disableReordering disableClear>
                                                    <SyncSum/>
                                                    <Ra.NumberInput source="sum" label="Сумма" validate={Ra.required()} min={0} helperText={false}/>
                                                    <Ra.DateInput source="plannedPaymentDate" label="Планируемая дата оплаты" validate={Ra.required()} defaultValue={null} helperText={false}/>
                                                    <Ra.DateInput source="paymentDate" label="Дата оплаты" helperText={false}/>
                                                    <Ra.TextInput source="comment" label="Комментарий к платежу" fullWidth multiline/>
                                            </Ra.SimpleFormIterator>
                                        </Ra.ArrayInput>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Ra.TextInput source="details.comment" label="Комментарий к периоду" fullWidth multiline/>
                                    </Grid>
                                    </Grid>
                                </DialogContent>
                                <DialogActions>
                                    <Ra.Button label="ОТМЕНА" variant='contained' size="medium" onClick={onClose}><Cancel/></Ra.Button>
                                    <Ra.SaveButton  alwaysEnable/>
                                </DialogActions>
                            </Ra.Form>
                        </Ra.CreateBase>
                    }
                    {/* initial && (
                    <Form onSubmit={onSave}
                    
                    initialValues={initial}
                    render={({handleSubmit, form, submitting, pristine, values}:any)=>(
                        <form onSubmit={handleSubmit}>
                            <DialogContent>
                                <Grid container spacing={1}>
                                <Grid item xs={6}>
                                    <DateTimeInput source="startTime" label="Начало" fullWidth validate={required()}/>
                                </Grid>
                                <Grid item xs={6}>
                                    <NumberInput source="duration" label="Месяцев" fullWidth min={1} validate={required()}/>
                                </Grid>
                                {/*<Grid item xs={4}>
                                    <DateTimeInput source="expireTime" label="Конец" fullWidth disabled/>
                                </Grid>*//*}
                                <Grid item xs={6}>
                                    <NumberInput source="tariff.numberOfDevices" label="Количество устройств" fullWidth validate={required()}/>
                                </Grid>
                                <Grid item xs={6}>
                                    <NumberInput source="tariff.pricePerPlayer" label="Цена за устройство" fullWidth validate={required()}/>
                                </Grid>
                                <Grid item xs={6}>
                                    <NumberInput source="tariff.storageSize" label="Размер хранилища в Гб" min={1} format={(v:number) => v/1024/1024/1024} parse={(v:number)=> v*1024*1024*1024} fullWidth validate={required()}/>
                                </Grid>
                                <Grid item xs={6}>
                                    <NumberInput source="tariff.pricePerStorageUnit" label="Цена за Гб"fullWidth validate={required()}/>
                                </Grid>
                                {/*<Labeled label="Сумма">
                                    <FunctionField render={(v:any)=>{console.log(v); return v?.tariff?.numberOfDevices*v?.tariff?.pricePerPlayer*v?.duration+v.tariff.storageSize/v. || 0;}} fullWidth/>
                                </Labeled>*//*}
                                <SelectInput source="details.paymentType" choices={PersonalPaymentTypes} label="Тип оплаты" fullWidth/>
                                <FormDataConsumer>
                                        {({formData})=> 
                                            formData.details?.paymentType==2 && (<NumberInput source="details.creditPercent" label="Процент кредита" min={0} fullWidth/>)
                                        }
                                </FormDataConsumer>
                                <Grid item xs={6}>
                                    <DateTimeInput source="details.plannedPaymentDate" label="Планируемая дата оплаты" fullWidth validate={required()}/>
                                </Grid>
                                <Grid item xs={6}>
                                    <DateTimeInput source="details.paymentDate" label="Дата оплаты" fullWidth/>
                                </Grid>
                                <TextInput source="details.comment" label="Комментарий" fullWidth multiline/>
                                </Grid>
                            </DialogContent>
                            <DialogActions>
                            <Button label="ОТМЕНА" variant='contained' size="medium" disabled={isLoading} onClick={onClose}><Cancel/></Button>
                            <SaveButton onClick={handleSubmit} disabled={isLoading}/> {/*TODO saving={isLoading} *//*}
                            </DialogActions>
                        </form>
                    )}>
                                    </Form> ) */}
                    {!initial && <DialogContent><CircularProgress/></DialogContent>}
            </Dialog>
        </>
    );
}