import React from "react";
import * as Ra from 'react-admin';
import { Summarize, Tune, Event } from '@mui/icons-material';

export const AppMenu=(props:any)=>{
    const {permissions} = Ra.usePermissions();

    return (
        <Ra.Menu>
            <Ra.Menu.ResourceItem name='companies'/>
            <Ra.Menu.ResourceItem name='signuprequests'/>
            <Ra.Menu.ResourceItem name='users'/>
            <Ra.Menu.ResourceItem name='distibutors'/>
            { permissions && permissions.includes("Admin") && <Ra.Menu.ResourceItem name='externalsellers'/> }
            { permissions && permissions.includes("Admin") && <Ra.Menu.ResourceItem name='tariffs'/> }
            { permissions && permissions.includes("Admin") && <Ra.Menu.Item to='/constructor' primaryText="Публичный тариф" leftIcon={<Tune/>}/> }
            <Ra.Menu.ResourceItem name='devices'/>
            <Ra.Menu.Item to='/statistics' primaryText="Статистика" leftIcon={<Summarize/>}/>
            { permissions && permissions.includes("Admin") && <Ra.Menu.Item to='/eventSubscriptions' primaryText="Уведомления" leftIcon={<Event/>}/> }
        </Ra.Menu>
    )
}