import * as React from 'react'
import { List, Datagrid, ListProps, TextField, Filter, TextInput, BulkDeleteButton, DateField, CreateButton } from 'react-admin';
import ApproveButton from './ApproveButton';

const SignUpRequestsFilter:React.FC<any> = (props:any)=>(
    <Filter {...props}>
        <TextInput label="Search" source="q" alwaysOn resettable/>
    </Filter>
)

const BulkActionButtons:React.FC<any> = (props) => (
    <React.Fragment>
        <BulkDeleteButton {...props} />
    </React.Fragment>
);

const SignUpRequestsList:React.FC<ListProps> = (props:ListProps)=>{
    return (
    <List {...props} exporter={false} bulkActionButtons={<BulkActionButtons/>} filters={<SignUpRequestsFilter/>}>
        <Datagrid rowClick="edit">
            <TextField source="name" label="Название" sortable={true}/>
            <TextField source="email" label="Email" sortable={true}/>
            <TextField source="phone" label="Телефон" sortable={true}/>
            <DateField source="created" label="Создан" sortable={true}/>
            <ApproveButton label="Действия"/>
        </Datagrid>
    </List>
)
}

export default SignUpRequestsList;
