import React from 'react';
import { Edit, EditProps } from 'react-admin';
import {DistibutorForm} from './Form';

const DistibutorEdit:React.FC<EditProps> = (props:EditProps) => (
    <Edit {...props} mutationMode='pessimistic' >
        <DistibutorForm/>
    </Edit>
);

export default DistibutorEdit;