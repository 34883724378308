import * as React from "react";
import { Link } from 'react-router-dom';
//import Button from '@mui/material/Button';
import { NavLink } from "react-router-dom";
import { Button, useRecordContext } from "react-admin";

const ApproveButton = (props:any) => {
    const record = useRecordContext();
    const stopPropagation = React.useCallback((e:any)=>{
        e.stopPropagation();
    },[]);
    return <Button
        size="small"
        color="primary"
        component={Link}
        to={{pathname:'/companies/create' , state:{ record:{ name:record.name, email: record.email, "info.personEmail": record.email, "info.personPhone": record.phone, signUpRequestId: record.id }}}}
        onClick={stopPropagation}
        label="Создать компанию"
        />;
};

export default ApproveButton;