import * as Ra from 'react-admin';

export const EventSubscriptions = ()=>{
    return (
        <Ra.Edit resource="globalEventSubscriptions" id="0" title="Уведомления" redirect={false} mutationMode='pessimistic'>
            <Ra.SimpleForm>
                <Ra.ReferenceArrayInput source="events" reference='globalEventSubscriptions/events'>
                    <Ra.CheckboxGroupInput label='События' row={false}/>
                </Ra.ReferenceArrayInput>
            </Ra.SimpleForm>
        </Ra.Edit>
    )
}