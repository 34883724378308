import React, { useCallback, useState } from "react";
import * as Ra from 'react-admin';
import ActionDelete from '@mui/icons-material/Delete';

const CancelPeriodButton = (props:any)=>{
    const dataProvider = Ra.useDataProvider();
    const record = Ra.useRecordContext(props);
    const notify = Ra.useNotify();
    const { refetch } = Ra.useListContext();
    

    const [isOpen, setIsOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [deleted, setDeleted] = useState(false);//хак тк хз почему но record.cancelTime не обновляется
    const onClick = useCallback(()=>{
        setIsOpen(true);
    },[setIsOpen]);

    const onClose = useCallback(()=>{
        setIsOpen(false);
    },[setIsOpen]);

    const onDelete = useCallback(()=>{
        setIsLoading(true);
        (async ()=>{
            setIsLoading(true);
            try{
                await dataProvider.delete('tariffperiods',{ id: record.id });
                notify('Период отменен', { type: "success"});
                setDeleted(true);
                refetch();
            }catch(e){
                console.log(e);
                var message = "Unknown error";
                if(e instanceof Error) message= e.message;
                if(e instanceof Ra.HttpError) message = e.body;
                notify(message, { type: "error"});
            }
            setIsLoading(false);
            setIsOpen(false);
        })();
    },[setIsLoading, setIsOpen, dataProvider, record, notify, refetch, setDeleted])


    return (
        <>
            { !(record.cancelTime || deleted) && (
                <Ra.Button label="ОТМЕНИТЬ ПЕРИОД" color="error" size="medium" onClick={onClick} {...props}>
                    <ActionDelete/>
                </Ra.Button>
            )}
            <Ra.Confirm
                isOpen={isOpen}
                title="Действительно хотите отменить период?" 
                content=""
                onConfirm={onDelete}
                onClose={onClose}
                loading={isLoading}
            />
        </>
    )
}

export const PeriodShowToolbar=(props:any)=>{
    const record = Ra.useRecordContext(props);
    return (
    <Ra.Toolbar {...props}>
        {props.children}
        <Ra.Button label="ОТПРАВИТЬ ДОКУМЕНТЫ" size="medium" variant="contained" href={`mailto://${record?.information?.email}`} style={{marginLeft:'1rem', marginRight:'1rem', display: record?.tariff?.type=="personal" ? "inherit" :"none"}}/>
        {/*<Ra.DeleteButton mutationOptions={ { onSuccess : onDelete }} redirect={false} label="ОТМЕНИТЬ ПЕРИОД" confirmTitle="Действительно хотите отменить период?" confirmContent="" style={record?.cancelTime && {display:"none"}}/>*/}
        <CancelPeriodButton/>
    </Ra.Toolbar>)
}