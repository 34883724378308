import React from 'react';
import { Create, CreateProps } from 'react-admin';
import {ExternalSellersForm} from './Form';

const ExternalSellersCreate:React.FC<CreateProps> = props => (
    <Create {...props}>
        <ExternalSellersForm/>
    </Create>
);

export default ExternalSellersCreate;