export const TariffTypes = [
    { 
        id: 'base',
        name: 'Базовый'
    },
    { 
        id: 'trial',
        name: 'Пробный'
    },
    { 
        id: 'google_play_subscription',
        name: 'Подписка GooglePlay'
    },
    { 
        id: 'appstore_subscription',
        name: 'Подписка AppStore'
    },
    { 
        id: 'personal',
        name: 'Персональный'
    },
    { 
        id: null,
        name: 'Без тарифа'
    }
];