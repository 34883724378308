import React, { useCallback, useState } from "react";
import { CircularProgress } from '@mui/material';
import { Archive, Restore} from '@mui/icons-material';
import * as Ra from 'react-admin';
import { baseUrl, httpClient } from "../dataProvider";

export const InvoiceFileArchiveButton = ({...props}:any)=>{
    const record = Ra.useRecordContext();
    const notify = Ra.useNotify();
    const { refetch } = Ra.useListContext();

    const [loading, setLoading] = useState(false);

    const onConfirm = useCallback(async ()=>{
        setLoading(true);
        try{
            const id = record.id;
            const res = await httpClient(`${baseUrl}/invoicefiles/${id}/${record?.archived !==null ?  "restore" : "archive"}`, { method: 'PUT' });
            if(res.status == 200){
                refetch();
            } else throw Error("Can`t confirm period");
        }catch(e){
            var message = "Unknown error";
            if(e instanceof Error) message=e.message;
            if(e instanceof Ra.HttpError) message = e.body;
            notify(message, { type: "error"});
        }
        setLoading(false);
    },[setLoading, refetch, record?.id, record?.archived]);

    return (
        <Ra.Button label={record?.archived !==null ?  "Восстановить" : "Архивировать"} size="medium" disabled={loading} style={props.style} onClick={onConfirm}>
            {loading ? <CircularProgress size='1rem'/> : (record?.archived !==null ?  <Restore/> : <Archive/>) }
        </Ra.Button>
    )
}
