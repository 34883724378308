import jsonServerProvider from 'ra-data-json-server';
import { fetchUtils } from 'react-admin';
import isDev from './utils/isDev';

export const httpClient = (url:any, options:fetchUtils.Options = {}) => {
    var headers=  options.headers as Headers;
    if (!headers) {
        headers = new Headers();
        headers.set("Accept", 'application/json');
    }
    const user = localStorage.getItem('user');
    if(user){
        const { token } = JSON.parse(user);
        headers.set('Authorization',`Bearer ${token}`);
    }
    options.headers = headers;
    return fetchUtils.fetchJson(url, options);
};

export const baseUrl = isDev() ? 'http://localhost:6007/api' : '/api';

const dataProvider = jsonServerProvider(baseUrl, httpClient);

export default dataProvider;