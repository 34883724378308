import React, { useCallback, useState } from "react";
import { Dialog, DialogTitle, DialogContent, DialogActions,Grid, CircularProgress } from '@mui/material';
import { Add, Cancel } from '@mui/icons-material';
import * as Ra from 'react-admin';

import { baseUrl, httpClient } from "../dataProvider";
import { SizeInput } from "../components/SizeInput";
import { UTCDateTimeInput } from "../components/UTCDateTimeInput";

export const AddTrialPeriodButton = (props:any)=>{
    const notify = Ra.useNotify();
    const { refetch } = Ra.useListContext();
    const record = Ra.useRecordContext();
    const [showDialog, setShowDiadlog] = useState(false);
    const [initial, setInitial] = useState<any>(undefined);

    const onAdd = useCallback(async ()=>{
        setShowDiadlog(true);
        try{
            const res = await httpClient(`${baseUrl}/tariffperiods/${record.id}/trial_prototip`);
            if(res.json){
                setInitial(res.json);
            } else throw Error("Can`t create new period");
        }catch(e){
            var message = "Unknown error";
            console.log(e);
            if(e instanceof Error) message= e.message;
            if(e instanceof Ra.HttpError) message = e.body;
            notify(message, { type: "error"});
            setShowDiadlog(false);
        }
    },[notify,setInitial,record,setShowDiadlog]);

    const onClose=useCallback((reason)=>{
        console.log(reason);
        if(reason?.type==='mouseup') return;
        setShowDiadlog(false);
        setInitial(undefined);
    },[setShowDiadlog,setInitial]);

    const onSuccess = useCallback(()=>{
        refetch();
        onClose('complete');
    },[refetch,onClose]);

    const onError = useCallback((e)=>{
        var message = "Unknown error";
        if(e instanceof Error) message=e.message;
        if(e instanceof Ra.HttpError) message = e.body?.message || JSON.stringify(e.body);
        notify(message, { type: "error"});
    },[notify]);

    return (
        <>
            <Ra.Button onClick={onAdd} label="Добавить пробный период" variant="contained" size="medium" style={props.style}><Add/></Ra.Button>
            <Dialog
                open={showDialog}
                onClose={onClose}
                aria-label="Добавить пробный период">
                <DialogTitle>Добавить пробный период</DialogTitle>
                    { initial && 
                        <Ra.CreateBase resource="tariffperiods" redirect={false} record={initial} mutationOptions={{onSuccess, onError}}>
                            <Ra.Form >
                                <DialogContent>
                                    <Grid container spacing={1}>
                                        <Grid item xs={6}>
                                            <UTCDateTimeInput source="startTime" label="Начало" fullWidth validate={Ra.required()}/>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Ra.NumberInput source="duration" label="Дней" fullWidth min={1} max={30} validate={Ra.required()}/>
                                        </Grid>
                                        {/*<Grid item xs={4}>
                                            <DateTimeInput source="expireTime" label="Конец" fullWidth disabled/>
                                        </Grid>*/}
                                        <Grid item xs={4}>
                                            <Ra.NumberInput source="tariff.numberOfDevices" label="Количество устройств" min={1} fullWidth validate={Ra.required()}/>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <SizeInput source="tariff.storageSize" label="Размер хранилища" fullWidth validate={Ra.required()}/>
                                            {/*<Ra.NumberInput source="tariff.storageSize" label="Размер хранилища в Гб" min={1} format={(v:number) => v/1024/1024/1024} parse={(v:number)=> v*1024*1024*1024} fullWidth validate={Ra.required()}/>*/}
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Ra.NumberInput source="tariff.numberOfUsers" label="Количество пользователей" min={0} fullWidth validate={Ra.required()}/>
                                        </Grid>
                                    </Grid>
                                </DialogContent>
                                <DialogActions>
                                    <Ra.Button label="ОТМЕНА" variant='contained' size="medium" onClick={onClose}><Cancel/></Ra.Button>
                                    <Ra.SaveButton  alwaysEnable/>
                                </DialogActions>
                            </Ra.Form>
                        </Ra.CreateBase>
                    }
                    {!initial && <DialogContent><CircularProgress/></DialogContent>}
            </Dialog>
        </>
    );
}