import * as React from 'react'
import { List, Datagrid, TextField, Filter, TextInput, ReferenceField, ReferenceInput, SelectInput, SelectField, ListProps } from 'react-admin';
import { TariffTypes } from '../tariffs/TariffTypes'

const CompaniesFilter:React.FC<any> = (props:any)=>(
    <Filter {...props}>
        <TextInput label="Search" source="q" alwaysOn resettable/>
        <ReferenceInput source="distibutor" label="Дистрибьютор" reference="distibutors" alwaysOn resettable>
            <SelectInput optionText="name" />
        </ReferenceInput>
    </Filter>
)

const CompaniesList:React.FC<ListProps> = (props:ListProps)=>(
    <List {...props} exporter={false} bulkActionButtons={false} filters={<CompaniesFilter/>}>
        <Datagrid rowClick="edit">
            <TextField source="name" label="Название" sortable={true}/>
            <ReferenceField label="Дистрибьютор" source="distibutorId" reference="distibutors">
                <TextField source="name" />
            </ReferenceField>
            <SelectField source="activePeriodType" label="Тип тарифа" choices={TariffTypes} emptyText="Без тарифа" sortable={true}/>
            <TextField source="paymentState" label="Оплата" sortable={true}/>
        </Datagrid>
    </List>
)

export default CompaniesList;
