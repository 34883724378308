import React from 'react';
import {Admin, CustomRoutes, Resource} from 'react-admin';
import polyglotI18nProvider from 'ra-i18n-polyglot';
import {AppLayout} from './layout';
import dataProvider from './dataProvider';
import authProvider from './authProvider';

import ru from './i18n/ru';

import companies from './companies';
import signuprequests from './signuprequests';
import users from './users';
import externalSellers from './externalSellers';
import tariffs from './tariffs';
import distibutors from './distibutors';
import devices from './devices';

import './App.css';
import { Route } from 'react-router-dom';
import { Statistics } from './statistics';
import { TariffContructor } from './tariffConstructor';
import { EventSubscriptions } from './eventSubscriptions';

const i18nProvider = polyglotI18nProvider(()=>ru, 'ru');

function App() {
  return (
    <Admin
      title=""
      dataProvider={dataProvider}
      authProvider={authProvider}
      i18nProvider={i18nProvider}
      layout={AppLayout}
    >
      <Resource name="companies" {...companies}/>
      <Resource name="signuprequests" {...signuprequests}/>
      <Resource name="companydocuments"/>
      <Resource name="users" {...users}/>
      <Resource name="tariffperiods"/>
      <Resource name="externalsellers" {...externalSellers}/>
      <Resource name="tariffs" {...tariffs}/>
      <Resource name="distibutors" {...distibutors}/>
      <Resource name="devices" {...devices}/>
      <CustomRoutes>
        <Route path="/statistics" element={<Statistics/>} />
        <Route path='/constructor' element={<TariffContructor/>}/>
        <Route path='/eventSubscriptions' element={<EventSubscriptions/>}/>
      </CustomRoutes>
    </Admin>
  );
}

export default App;
