import React from 'react';
import { Edit, EditProps } from 'react-admin';
import {ExternalSellersForm} from './Form';

const ExternalSellersEdit:React.FC<EditProps> = (props:EditProps) => (
    <Edit {...props} mutationMode='pessimistic' >
        <ExternalSellersForm/>
    </Edit>
);

export default ExternalSellersEdit;