import React from "react";
import { Datagrid, Filter, FunctionField, List, SelectInput, TextField, TextInput } from "react-admin";
import {userTypes} from './types';

const UsersFilter = (props:any)=>(
    <Filter {...props}>
        <TextInput label="Поиск" source="q" alwaysOn resettable/>
        <SelectInput label="Тип" source="type" alwaysOn resettable choices={userTypes}/>
    </Filter>
)

export const UserList = (props:any)=>(
    <List {...props} exporter={false} bulkActionButtons={false} filters={<UsersFilter/>} hasCreate={false}>
        <Datagrid rowClick='edit'>
            <TextField source="name" label="Имя" sortable={true}/>
            <TextField source="email" label="Email" sortable={true}/>
            <FunctionField label="Тип" render={(record:any)=> userTypes.find(t=>t.id==record.type)?.name }/>
        </Datagrid>
    </List>
)