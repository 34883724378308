import React from "react";
import * as Ra from 'react-admin';

export const GooglePlaySubscriptionShow = (props:any)=>(
    <Ra.Show {...props} title=' '>
        <Ra.SimpleShowLayout>
            <Ra.ReferenceField source="tariff.id" reference="tariffs" label="Тариф">
                <Ra.TextField source="productId"/>
            </Ra.ReferenceField>
            <Ra.ReferenceField source="tariff.externalSellerId" reference="externalsellers" label="Приложение">
                <Ra.TextField source="identifier"/>
            </Ra.ReferenceField>
            <Ra.TextField source='details.token' label="Токен покупки"/>
            <Ra.TextField source='details.linkedPurchaseToken' label="Связано с другим токеном покупки"/>
            <Ra.TextField source='details.latestOrderId' label="Ид последнего заказа в GooglePlay"/>
            <Ra.TextField source='details.initialRenewPeriod' label="Начальный период обновления"/>
            <Ra.TextField source='details.initialCurrency' label="Начальная валюта"/>
            <Ra.TextField source='details.initialPrice' label="Начальная стоимость"/>
        </Ra.SimpleShowLayout>
    </Ra.Show>
)