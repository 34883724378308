import { ReferenceArrayInput, required, SelectInput, SimpleForm, TextInput, ArrayInput, SimpleFormIterator } from 'react-admin';

export const DistibutorForm=(props:any)=>{
    return (
        <SimpleForm {...props}>
            <TextInput source="name" label="Название" validate={required()}/>
            <ArrayInput source="users" label="Пользователи">
                    <SimpleFormIterator>
                        <TextInput source="name" label="Имя" validate={required()} />
                        <TextInput source="email" label="Email" validate={required()} />
                    </SimpleFormIterator>
            </ArrayInput>
        </SimpleForm>
    );
}