import { useNotify} from 'react-admin';
import axios from 'axios';
import { baseUrl }from '../dataProvider';

async function processProperty(property:any, notify:any){
    if(property && property.rawFile){
        const fd = new FormData();
        fd.append("file", property.rawFile);
        const response = await axios.post(baseUrl+"/files/upload", fd);
        console.log('response',response);
        if(response.status!=200){
            console.log("Upload error. "+response.statusText);
            notify("Upload error. "+response.statusText);
            return null;
        }
        property.fileId = response.data.id;
    }
}

async function processObj(property:any, notify:any){
    if(typeof(property)!=="object") return;
    await processProperty(property, notify);
    for(const k in property){
        await processObj(property[k], notify);
    }
}

export default function useFileTransform(){
    const notify= useNotify();
    return (async (data:any)=>{
        console.log('transform ', data);
        await processObj(data, notify);
        return data;
    }) as any;
}