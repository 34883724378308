import { useCallback, useState } from 'react';
import * as Ra from 'react-admin';
import { Add, Cancel } from '@mui/icons-material';
import { Dialog, DialogTitle, DialogContent, DialogActions,Grid, CircularProgress } from '@mui/material';
import useFileTransform from '../utils/useFileTransform';

export const AddInvoiceFileButton = (props:any)=>{
    const notify = Ra.useNotify();
    const { refetch } = Ra.useListContext();
    const [showDialog, setShowDiadlog] = useState(false);
    const record = Ra.useRecordContext();
    const [initial, setInitial] = useState<any>(undefined);
    const onAdd = useCallback(()=>{
        setInitial({companyId: record.id});
        setShowDiadlog(true);
    },[setShowDiadlog, record, setInitial]);

    const onClose=useCallback((reason)=>{
        console.log(reason);
        if(reason?.type==='mouseup') return;
        setShowDiadlog(false);
    },[setShowDiadlog]);

    const onSuccess = useCallback(()=>{
        refetch();
        onClose('complete');
    },[refetch,onClose]);

    const onError = useCallback((e)=>{
        var message = "Unknown error";
        if(e instanceof Error) message=e.message;
        if(e instanceof Ra.HttpError) message = e.body?.message || JSON.stringify(e.body);
        notify(message, { type: "error"});
    },[notify]);

    return (
        <>
            <Ra.Button onClick={onAdd} label="Добавить счет" variant="contained" size="medium" style={props.style} {...props}><Add/></Ra.Button>
            <Dialog
                open={showDialog}
                onClose={onClose}
                aria-label="Добавить счет"
                maxWidth="md">
                <DialogTitle>Добавить счет</DialogTitle>
                <Ra.CreateBase resource="invoicefiles" redirect={false} record={initial} mutationOptions={{onSuccess, onError}} transform={useFileTransform()}>
                            <Ra.Form >
                                <DialogContent>
                                    <Ra.FileInput source="file" label="Счет" isRequired>
                                        <Ra.FileField source="src" title="title" target="_blank"/>
                                    </Ra.FileInput>
                                </DialogContent>
                                <DialogActions>
                                    <Ra.Button label="ОТМЕНА" variant='contained' size="medium" onClick={onClose}><Cancel/></Ra.Button>
                                    <Ra.SaveButton/>
                                </DialogActions>
                            </Ra.Form>
                </Ra.CreateBase>
            </Dialog>
        </>
    )
}