import { get } from "lodash";
import { useRecordContext } from "react-admin";
import { beutifySize } from "../utils/beutifySize";
import Typography from '@mui/material/Typography';

export const SizeField =(props:any)=>{
    const {source} = props;
    const record = useRecordContext(props);
    var value = get(record, source);
    if(value) value = beutifySize(value);
    return (
        <Typography
            component="span"
            variant="body2"
            {...props}
        >
            {value}
        </Typography>
    );
}

SizeField.defaultProps = {
    textAlign: 'right',
};