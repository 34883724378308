import { DateTimeInput, DateTimeInputProps } from "react-admin";

export const UTCDateTimeInput = (props:DateTimeInputProps)=>(
    <DateTimeInput format={format} parse={parse} {...props}/>
)

const parse = (value: string) => {
    console.log('parse',value+'Z')
    return value ? new Date(value+'Z') : value === '' ? null : value;
}


const format = (value: string | Date) => {
    console.log('format',value)
    if (value == null || value === '') {
        return '';
    }

    if (value instanceof Date) {
        return convertDateToString(value);
    }

    return convertDateToString(new Date(value+'Z'));
}

const leftPad = (nb = 2) => (value: number) => ('0'.repeat(nb) + value).slice(-nb);
const leftPad4 = leftPad(4);
const leftPad2 = leftPad(2);

const convertDateToString = (value: Date) => {
    if (!(value instanceof Date) || isNaN(value.getDate())) return '';
    const yyyy = leftPad4(value.getUTCFullYear());
    const MM = leftPad2(value.getUTCMonth() + 1);
    const dd = leftPad2(value.getUTCDate());
    const hh = leftPad2(value.getUTCHours());
    const mm = leftPad2(value.getUTCMinutes());
    return `${yyyy}-${MM}-${dd}T${hh}:${mm}`;
};