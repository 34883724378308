import React from 'react';
import { BooleanField, DateField, Edit, EditProps, Labeled, NumberField, ReferenceField, SelectField, SimpleForm, TextField, FormDataConsumer, Toolbar, DeleteButton } from 'react-admin';
import { SizeField } from '../components/SizeField';
import { SizeInput } from '../components/SizeInput';
import { TariffTypes } from './TariffTypes';
import { Typography } from "@mui/material";

const DisableToolbar=(props:any)=>{
    return (
    <Toolbar {...props}>
        <DeleteButton redirect={false} label="ОТКЛЮЧИТЬ ТАРИФ" confirmTitle="Действительно хотите отключить тариф?" confirmContent="Отключение тарифа не отменяет действующие периоды." style={props?.record?.disabled && {display:"none"}}/>
    </Toolbar>)
}

const TariffEdit:React.FC<EditProps> = (props:EditProps) => (
    <Edit {...props} mutationMode='pessimistic' >
        <SimpleForm toolbar={<DisableToolbar/>}>
            <Labeled>
                <SelectField source="type" label="Тип" choices={TariffTypes}/>
            </Labeled>
            <Labeled>
                <DateField source="disabled" label="Дата отключения" emptyText="-"/>
            </Labeled>
            <Labeled>
                <BooleanField source="isPublic" label="Публичный"/>
            </Labeled>
            <Labeled>
                <NumberField source="numberOfDevices" label="Количество устройств"/>
            </Labeled>
            <Labeled label="Размер хранилища">
                <SizeField source="storageSize" />
            </Labeled>
            <Labeled>
                <NumberField source="numberOfUsers" label="Количество пользователей"/>
            </Labeled>

            <FormDataConsumer>
                    {({formData})=>
                        formData.type==="base" && formData.trialTariff && <Typography>Включает пробный период</Typography>
                    }
            </FormDataConsumer>

            <FormDataConsumer>
                    {({formData})=>
                        formData.type==="base" && formData.trialTariff && <Labeled label="Продолжительность дней"><NumberField source="trialTariff.duration"/></Labeled>
                    }
            </FormDataConsumer>
            <FormDataConsumer>
                    {({formData})=>
                        formData.type==="base" && formData.trialTariff && <Labeled label="Количество устройств"><NumberField source="trialTariff.numberOfDevices"/></Labeled>
                    }
            </FormDataConsumer>
            <FormDataConsumer>
                    {({formData})=>
                        formData.type==="base" && formData.trialTariff && <Labeled label="Размер хранилища"><SizeField source="trialTariff.storageSize" /></Labeled>
                    }
            </FormDataConsumer>
            <FormDataConsumer>
                    {({formData})=>
                        formData.type==="base" && formData.trialTariff && <Labeled label="Количество пользователей"><NumberField source="trialTariff.numberOfUsers"/></Labeled>
                    }
            </FormDataConsumer>

            <FormDataConsumer>
                    {({formData})=>
                        formData.type==="google_play_subscription" && (
                        <Labeled label="Приложение">
                            <ReferenceField source="externalSellerId" reference="externalsellers">
                                <TextField source="identifier"/>
                            </ReferenceField>
                        </Labeled>)
                    }
            </FormDataConsumer>
            <FormDataConsumer>
                    {({formData})=>
                        formData.type==="google_play_subscription" && <Labeled label="Идентификатор продукта"><TextField source="productId"/></Labeled>
                    }
            </FormDataConsumer>
            <FormDataConsumer>
                    {({formData})=>
                        formData.type==="google_play_subscription" && <Labeled label="Время обновления"><DateField source="updateTime"/></Labeled>
                    }
            </FormDataConsumer>
            <FormDataConsumer>
                    {({formData})=>
                        formData.type==="google_play_subscription" && <Labeled label="Версия"><TextField source="version"/></Labeled>
                    }
            </FormDataConsumer>
            
            <FormDataConsumer>
                    {({formData})=>
                        formData.type==="personal" && <Labeled label="Цена за устройство"><NumberField source="pricePerPlayer"/></Labeled>
                    }
            </FormDataConsumer>
            <FormDataConsumer>
                    {({formData})=>
                        formData.type==="personal" && <Labeled label="Цена за единицу хранилища"><NumberField source="pricePerStorageUnit"/></Labeled>
                    }
            </FormDataConsumer>
            <FormDataConsumer>
                    {({formData})=>
                        formData.type==="personal" && <Labeled label="Размер единицы хранилища"><SizeField source="storageUnit" /></Labeled>
                    }
            </FormDataConsumer>
            <FormDataConsumer>
                    {({formData})=>
                        formData.type==="personal" && <Labeled label="Цена за пользователя"><NumberField source="pricePerUser"/></Labeled>
                    }
            </FormDataConsumer>
            
        </SimpleForm>
    </Edit>
);

export default TariffEdit;