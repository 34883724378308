import React from 'react';
import { Edit, SimpleForm, TextInput, required,EditProps, TabbedForm, FormTab, ReferenceArrayInput, ArrayInput, SimpleFormIterator, FileInput, FileField, BooleanInput } from 'react-admin';
import useFileTransform from '../utils/useFileTransform';
import {CompanyForm} from './Form';

const CompaniesEdit:React.FC<EditProps> = (props:EditProps) => (
    <Edit {...props} mutationMode='pessimistic' transform={useFileTransform()}>
        <CompanyForm/>
    </Edit>
);

export default CompaniesEdit;