import React from 'react';
import { Create, CreateProps, SimpleForm, SelectField, NumberInput, required, SelectInput, BooleanInput, FormDataConsumer } from 'react-admin';
import { SizeInput } from '../components/SizeInput';
import { TariffTypes } from './TariffTypes';

const initial = ()=>({
    type:"base",
    applyType:0
});

const ApplyTypes = [
    {
        id:0,
        name: 'Только новые компании'
    },
    {
        id:1,
        name: 'новые компании и компании с базовым тарифом'
    },
    {
        id:2,
        name: 'все компании'
    },
]

const TariffCreate:React.FC<CreateProps> = props => (
    <Create {...props} title="Создать базовый тариф">
        <SimpleForm defaultValues={initial} >
            {/*<SelectField source="type" label="Тип" choices={TariffTypes}/>*/}
            <NumberInput source="numberOfDevices" label="Количество устройств" min={0} validate={required()}/>
            {/*<NumberInput source="storageSize" label="Размер хранилища в Гб" min={0.01} format={(v:number) => v/1024/1024/1024} parse={(v:number)=> v*1024*1024*1024} validate={required()}/>*/}
            <SizeInput source="storageSize" label="Размер хранилища" min={0} validate={required()}/>
            <NumberInput source="numberOfUsers" label="Количество пользователей" min={0} validate={required()}/>
            <SelectInput source="applyType" label="Применить" choices={ApplyTypes} />
            <BooleanInput source="hasTrial" label="Пробный период"/>

            <FormDataConsumer>
                    {({formData})=>
                                formData.hasTrial && <NumberInput source="trialDuration" label="Продолжительность дней" min={1} validate={required()}/>
                    }
            </FormDataConsumer>
            <FormDataConsumer>
                    {({formData})=>
                                formData.hasTrial && <NumberInput source="trialNumberOfDevices" label="Количество устройств" min={0}/>
                    }
            </FormDataConsumer>
            <FormDataConsumer>
                    {({formData})=>
                                formData.hasTrial && <SizeInput source="trialStorageSize" label="Размер хранилища" min={0}/>
                    }
            </FormDataConsumer>
            <FormDataConsumer>
                    {({formData})=>
                                formData.hasTrial && <NumberInput source="trialNumberOfUsers" label="Количество пользователей" min={0}/>
                    }
            </FormDataConsumer>
        </SimpleForm>
    </Create>
);

export default TariffCreate;