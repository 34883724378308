import { BooleanInput, Datagrid, DateField, FormDataConsumer, Pagination, ReferenceManyField, required, SelectInput, SimpleForm, TabbedForm, TextField, TextInput } from 'react-admin';
import { ExternalSellerTypes } from './ExternalSellerTypes';

export const ExternalSellersForm=(props:any)=>{
    return (
        <TabbedForm {...props}>
            <TabbedForm.Tab label='Данные'>
                <SelectInput source="type" label="Тип" choices={ExternalSellerTypes}/>
                <FormDataConsumer>
                    {({formData})=>
                                formData.type==="google_play_subscription" && <TextInput source="identifier" label="Идентификатор" validate={required()}/>
                    }
                </FormDataConsumer>
                <FormDataConsumer>
                    {({formData})=>
                                formData.type==="google_play_subscription" && <TextInput source="pubSubProject" label="PubSub Project" validate={required()}/>
                    }
                </FormDataConsumer>
                <FormDataConsumer>
                    {({formData})=>
                                formData.type==="google_play_subscription" && <TextInput source="pubSubTopic" label="PubSub Topic" validate={required()}/>
                    }
                </FormDataConsumer>
                <FormDataConsumer>
                    {({formData})=>
                                formData.type==="google_play_subscription" && <TextInput source="credentials" label="Credentials JSON" validate={required()} fullWidth multiline/>
                    }
                </FormDataConsumer>

                <FormDataConsumer>
                    {({formData})=>
                                formData.type==="appstore_subscription" && <TextInput source="identifier" label="BundleId" validate={required()}/>
                    }
                </FormDataConsumer>

                <FormDataConsumer>
                    {({formData})=>
                                formData.type==="appstore_subscription" && <BooleanInput source="isSandbox" label="Sandbox" validate={required()}/>
                    }
                </FormDataConsumer>

                <FormDataConsumer>
                    {({formData})=>
                                formData.type==="appstore_subscription" && <TextInput source="issuerId" label="AppStore ServerAPI - IssuerId" validate={required()} fullWidth/>
                    }
                </FormDataConsumer>
                <FormDataConsumer>
                    {({formData})=>
                                formData.type==="appstore_subscription" && <TextInput source="keyId" label="AppStore ServerAPI - Private keyId" validate={required()} fullWidth/>
                    }
                </FormDataConsumer>
                <FormDataConsumer>
                    {({formData})=>
                                formData.type==="appstore_subscription" && <TextInput source="key" label="AppStore ServerAPI - Private key" validate={required()} fullWidth multiline/>
                    }
                </FormDataConsumer>

                <FormDataConsumer>
                    {({formData})=>
                                formData.type==="appstore_subscription" && <TextInput source="appstoreConnectIssuerId" label="AppStore ConnectAPI - IssuerId" validate={required()} fullWidth/>
                    }
                </FormDataConsumer>
                <FormDataConsumer>
                    {({formData})=>
                                formData.type==="appstore_subscription" && <TextInput source="appstoreConnectKeyId" label="AppStore ConnectAPI - Private keyId" validate={required()} fullWidth/>
                    }
                </FormDataConsumer>
                <FormDataConsumer>
                    {({formData})=>
                                formData.type==="appstore_subscription" && <TextInput source="appstoreConnectKey" label="AppStore ConnectAPI - Private key" fullWidth multiline required/>
                    }
                </FormDataConsumer>
            </TabbedForm.Tab>
            <TabbedForm.Tab label='События'>
                <FormDataConsumer>
                    {({formData})=>
                        formData.id && (
                            <ReferenceManyField reference='externalSellerEvents' target='id' fullWidth sort={{field:'dateTime', order: 'DESC'}} pagination={<Pagination/>}>
                                <Datagrid  bulkActionButtons={false} sx={{'&.RaDatagrid-root':{ width:'100%' }}}>
                                    <DateField source='dateTime' showTime label='Время'/>
                                    <TextField source='externalTariffPeriodDetails.tariffPeriodId' label='Тарифный период'/>
                                    <TextField source='source' label='Источник'/>
                                    <TextField source='message' label='Сообщение'/>
                                    <TextField source='data' label='Данные'/>
                                </Datagrid>
                            </ReferenceManyField>
                            )
                    }
                </FormDataConsumer>
            </TabbedForm.Tab>
        </TabbedForm>
    );
}