import * as React from 'react'
import { List, Datagrid, ListProps, TextField, Filter, TextInput, SelectField } from 'react-admin';
import { ExternalSellerTypes } from './ExternalSellerTypes';

const ExternalSellersFilter:React.FC<any> = (props:any)=>(
    <Filter {...props}>
        <TextInput label="Search" source="q" alwaysOn resettable/>
    </Filter>
)

const ExternalSellersList:React.FC<ListProps> = (props:ListProps)=>(
    <List {...props} exporter={false} bulkActionButtons={false} filters={<ExternalSellersFilter/>}>
        <Datagrid rowClick="edit">
            <SelectField source="type" label="Тип" choices={ExternalSellerTypes}/>
            <TextField source="identifier" label="Идентификатор"/>
        </Datagrid>
    </List>
)

export default ExternalSellersList;
