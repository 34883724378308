import { GooglePlaySubscriptionShow } from "./GooglePlaySubscriptionShow";
import { BasePeriodShow } from "./BasePeriodShow";
import { PersonalPeriodShow } from "./PersonalPeriodShow";

export const TariffPeriodExpandSelector = (props:any)=>{
    switch(props.record?.tariff?.type){
        case 'personal':
            return <PersonalPeriodShow {...props}/>;
        case 'google_play_subscription':
            return <GooglePlaySubscriptionShow {...props}/>;
        case 'base':
        case 'trial':
            return <BasePeriodShow {...props}/>
        default: 
            return <p>{JSON.stringify(props)}</p>;
    }
}